<script setup lang="ts">
const {options} = useGlobalStore();
const {$activeModalsBus} = useNuxtApp();
</script>
<template>
  <div class="ft-contact">
    <div class="container">
      <div class="flex flex-row flex-wrap">
        <div class="col-md-7 col-span-full d-flex flex-wrap align-items-center justify-content-center">
          <div class="inner_content text-center w-100">
            <span class="title-h2">{{ options?.ftContact?.title }}</span>
            <div class="subtitle">{{ options?.ftContact?.subtitle }}</div>

            <div class="icons d-flex flex-row flex-wrap justify-md-center">
              <a class="icon-item col-md-3 col-12 mobile_only" :href="`tel:${options.phone}`" target="_blank">
                <div class="icon-warp relative">
                  <div class="icon">
                    <NuxtImg src="images/icons/phone.png" alt="אייקון לסימון יצירת קשר דרך התקשרות טלפונית"/>

                  </div>
                  <div class="icon-hover">
                    <img
                        src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/icons/phone_hover.png"
                        alt="אייקון צבעוני לסימון יצירת קשר דרך התקשרות טלפונית"
                    />
                  </div>
                </div>
                <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2"
                >לדבר בטלפון</span
                >
              </a>
              <a class="icon-item col-md-3 col-12 no_mobile" @click="$activeModalsBus.event('openPopupPhone')">
                <div class="icon-warp relative">
                  <div class="icon">
                    <nuxt-img src="/images/icons/phone.png" alt="אייקון לסימון יצירת קשר דרך התקשרות טלפונית"/>
                  </div>
                  <div class="icon-hover">
                    <nuxt-img
                        src="/images/icons/phone_hover.png"
                        alt="אייקון צבעוני לסימון יצירת קשר דרך התקשרות טלפונית"
                    />
                  </div>
                </div>
                <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2"
                >לדבר בטלפון</span
                >
              </a>
              <a class="icon-item col-md-3 col-12" :href="`https://wa.me/972${options?.whatsapp}`" target="_blank">
                <div class="icon-warp relative">
                  <nuxt-img src="/images/icons/whatsapp_hover.png" alt="וואטסאפ ציבעוני"/>
                </div>
                <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2"
                >להסתמס בוואטסאפ</span
                >
              </a>
              <a class="icon-item col-md-3 col-12" href="https://t.me/sasa_shtihim" target="_blank">
                <div class="icon-warp relative">
                  <nuxt-img class="icon" src="/images/svg/telegram-original.svg" alt="אייקון טלגרם"/>
                  <nuxt-img class="icon-hover" src="/images/svg/telegram-hover.svg" alt="אייקון טלגרם צבעוני"/>
                </div>
                <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2"
                >להתכתב בטלגרם</span
                >
              </a>
              <a class="icon-item col-md-3 col-12" @click="$activeModalsBus.event('openPopup')">
                <div class="icon-warp relative">
                  <div class="icon">
                    <nuxt-img src="/images/icons/msg.png" alt="אייקון של מעטפה לסימון יצירת קשר דרך אימייל"/>
                  </div>
                  <div class="icon-hover">
                    <nuxt-img
                        src="/images/icons/msg_hover.png"
                        alt="אייקון צבעוני של מעטפה לסימון יצירת קשר דרך אימייל"
                    />
                  </div>
                </div>
                <span class="subtitle-semibold font-weight-bold text-center red-underline red-underline--short2"
                >לשלוח מייל</span
                >
              </a>
            </div>
          </div>
        </div>
        <div class="col d-md-block no_mobile">
          <img src="https://admin.sasa.co.il/wp-content/uploads/2020/12/contact-us.png"
               alt="יצירת קשר שטיחי רכב SASA"/>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.ft-contact {
  @media screen and (max-width: 800px) {
    padding: 50px 0 50px 0;
  }
}
</style>
